import NavBar from "./Componants/NavBar";
import Home from "./Componants/Home";
import Footer from "./Componants/Footer";


function App() {
  return (
    <>
  <NavBar />
  <Home />
  <Footer />
  

  </>

  );
}

export default App;
